@media only screen and (max-width: 1920px) {
    .group-sec .total.amount {
        left: 40%;
    }
}
@media only screen and (max-width: 900px) {
    .image_one {
        position: inherit;
        right: 0;
        width: 58%;
    }
    .sidebar.mystyle{
        width: 300px;
        overflow: auto;
    }
    .text-area h1 {
        font-size: 34px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .desk{
     opacity: 0;
    }
    .mob{
        display: block;
    }

    .mob-top{
        display: block;
    }
    .sidebar{
        width: 0px;
    }
    .topbar .profile-image {
        margin: 0px 15px 0px 20px;
    }
    .orgnization-sec h2 {
        font-size: 16px;
    }
    .group-sec .item1{
        top: 18px;
        left: 23px;
    }
    .group-sec .item2{
        top: 209px;
    }
    .group-sec .item3{
        right: 15px;
    }
.group-reg {
display: inherit;
}
.dashboard-content .group-card{
    display: inherit;
}
.sidebar .navbar{
    opacity: 0;
}
.sidebar.mystyle .navbar{
    opacity: 1;
    margin-top: 72px;

}
.topbar .icon-menue {
    margin-left: 88px;
}

.dashboard-content{
    margin-left: 0px;
    margin-top: 145px;
    padding: 10px;
}
.orgnization-sec {
    margin: 10px 0px 0px 0px;
    padding: 20px;
}
.vl{
    display: none;
}
.revenue-sec {
    margin: 10px 0px 0px 0;
    padding: 20px 20px 39px;
}
.dashboard-content .group-card .section-box{
    margin: 10px 0px;
}
.topbar .innetr-group{
margin-left: 0px;

}
.item {
    width: 100%;
    padding: 10px;
}
.item-bg-login{
    width: 100%;
    padding: 0px;
}
.item-bg{
    width: 100%;
    padding: 0px;
}
.item-bg h1 {
    margin: 0px;
}
}

@media only screen and (max-width: 1600px) {
    .bid-table{
        overflow: auto;
     }
     .orgnization-table{
        overflow: auto;
     }
     .group-sec .total.amount{
        left: 41%;
     }
}


@media only screen and (max-width: 768px) {
    .second.card.cardone {
        position: inherit;
        margin-top: 0px;
        transform:inherit;
        width: 100%;
        left: 0px;
    }
    .padding {
        padding: 93px 0px;
    }
    .second.card h1{
        font-size: 20px;
    }
    .landing-header-logo {
        width: 134px;
    }
    .btn-outline-success1{
        margin-bottom: 10px;
    }
    .paragraphtext {
        font-size: 14px;
    }
    .section-auction-sport h1 {
        font-size: 20px;
        line-height: 1.4;
    }
    .section-auction h1 {
        font-size: 20px;
        line-height: 1.4;
    }
    .item-slider{
        width: 94%;
    }
    .section-auction h1.auction-img {
        font-size: 45px;
    }
    h1.pt-4.pb-4.text-regiter {
        color: #0b143f;
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
    }
    .second.card.padding-section {
        padding: 30px; 
        height: inherit;
    }
  
    .rhm-rn-navigation__button {
        padding: 0 37px !important;
    }
    .second.card.thirdcard {
        position: inherit;
        margin-top: 36px;
        transform: inherit;
        width: 100%;
        right:0px;
    }
    .image_one {
        position: inherit;
        right: 0;
        width: 100%;
    }
    .text-area h1 {
        font-size: 20px;
        line-height: 1.2;
    }
}






@media only screen and (max-width: 1366px) {
    .header .nav-link {
        margin: 0 0px;
   }
.group-sec .total.amount {
   display: none;
}
.total.Count.mob{
    display: none;
}
.image-auction{
display: none;
}
.total.amount.des{
    display: block;
    }
    .total.Count.des{
        display: block;
      }
    .item {
        padding: 0px 60px;
    }
    .subscription {
        padding: 20px;
        width: 60%;
    }
    .tabs-box .rmsc .dropdown-container {
        width:inherit;
    }
    .notification-dash {
        overflow: auto;
        height: 108vh;
    }
    .Bidder-sec {
        padding: 20px;
        min-height: 55vh;
    }
    .banner {
        margin-top: 70px;
    }
    .second.card.padding-section.subscrption-card {
        padding: 40px 18px;
        height: inherit;
    }
    .btn-outline-success1 {
        padding: 12px 20px;
    }
}
@media only screen and (max-width: 1600px) and (min-width:1400px) {
    .item {
            padding: 50px;
    }
    .header .nav-link {
        margin: 0 0px;
   }
}




@media only screen  and (min-width:1201px) and (max-width: 1365px){
    .landing-header-logo {
        width: 200px;
    }
    nav.header.navbar.navbar-expand-lg.navbar-light {
        padding: 0px 28px;
    }
 
    .btn-outline-success {
        padding: 12px 20px;
        font-size: 18px;
    }
    .header .btn.btn-primary {
        padding: 12px 20px;
        font-size: 18px;
    }
    .header .nav-link {
        padding: 5px 10px;
        margin: 0 5px;
    }
}


@media only screen and (max-width: 999px) {
 
    .rhm-rn-navigation__icon, .rhm-rn-navigation__icon::before, .rhm-rn-navigation__icon::after {
        background-color: #ffffff !important;
     }
     .rhm-rn-navigation__checkbox:checked+.rhm-rn-navigation__button .rhm-rn-navigation__icon {
        background-color: transparent !important;
        transition: 0.5s !important;
    }
     .rhm-rn-navigation__button{
        background-color: #051f83 !important;
     }
    .group-sec .total.amount {
        left: 33%;
    }
    .subscription {
        padding: 20px;
        width: 100%;
    }
    .editprofileicon {
        top: 0px;
   
   
    }
    .editprofileimage img {
        width: 100%;
        border-radius: 50%;
        height: auto;
        border: 2px solid #b9b9b9;
    }
    .editprofileimage {
        position: relative;
        width: 166px;
        margin: auto;
    }
    .item-list{
        margin-bottom: 15px;
    }
  
    .text-right-date{
        text-align: left;
    }
    .section-auction h1.auction-img {
        font-size: 74px;
        color: #bfbfbf;
        letter-spacing: 4px;
        margin-top: 14px;
    }
    .banner-item-Manager-tips {
        background-position: center;
    }
    .p-5.about-us-page.Faq-sec{
     padding: 10px !important;
    }
    .banner-item-Faq {
        background-position: center;
    }
    .banner-item-contact-us {
        background-position: center;
    }
    .contact-area {
        background: #dce0f1;
        padding: 20px 10px;
    }
    .item {
        padding: 0px 30px;
    }
    .landing-header-logo {
        width: 163px;
    }
    .banner-item-About-us {
       background-position: center;
    }
    .banner-content img {
        width: 100%;
    }
    .slider-image-about{
        height: inherit;
    }
    .banner-item-how-it-work{
        background-position: center;
    }
    .carousel-control-next {
        right: -30px !important;
        top:28px!important;
    }
    .carousel-control-prev {
        left: -30px !important;
        top:28px !important;
    }
    .bg-promocode-btn {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        margin-right: 15px;
        font-weight: bold;
        padding: 12px 40px;
        border: 1px solid;
        margin-top: 14px;
        background: #0d6efd;
    }
  
    .rhm-rn-navigation__button {
        padding: 0 61px !important;
  }
  .rhm-rn-navigation ul:not(.rhm-rn-navigation__logo) li {
    width: 100%;
}
  .btn-outline-success1 {
    padding: 12px 28px;
}
.container, .container-md, .container-sm {
    max-width: 955px;
}
.rhm-rn-navigation__checkbox:checked ~ .rhm-rn-navigation__nav {
    margin-top: 70px;
    width: 260px !important;
    height: 100% !important;
    align-items: flex-start;
}
    p.paragraph {
        width: 100%;
    }
    .image_one {
        width: 78%;
      }
    .text-area h1 {
        font-size: 42px;
    }
    .paragraphtext{
        padding: 0px;
        margin-top: 30px;
    }
    .header .nav-link {
         margin: 0 0px;
    }
    .group-reg {
        display: inherit;
        }
        .item-bg-login{
            width: 100%;
            padding: 30px;
        }
    .item {
        width: 100%;
        min-height: inherit;
    }
    .footer-content {
        margin: 0px 20px 20px 0px;
         margin-left: 0px; 
    }
    
    .item-bg{
        width: 100%;
        padding: 0px;
    }
    .item-bg-login h1{
        margin: 0 auto;
        padding: 45px 0px 0px;
        font-size: 30px;
        line-height: 1.2;
    }
    .item-bg h1 {
        margin: 0px;
        padding: 45px 0px 0px;
        font-size: 30px;
        line-height: 1.2;
       
    }
    .second.card.padding-section {
        box-shadow: none;
        padding: 10px;
        height: 400px;
        width: 100% !important;
    }
    .second.card h1 {
        line-height: 1.2;
    }
    .item-bg p {
   margin: 0px auto;
   padding: 25px 0px;
  }
  .rhm-rn-navigation__button {
    right: 18px;

}
.second.card {
    position: inherit !important;
    transform: inherit !important;
    width: inherit !important;
    margin: 10px auto !important;
    left: 0 !important;
}
.icon-back {
    margin-left: 10px;

}
}


@media only screen  and (min-width:991px) and (max-width: 1200px){
    .tabs-box .rmsc .dropdown-container {
        width: 100%;
    }
    .item {
     padding: 30px;
    }
    .item-bg{
        padding: 0px;
    }
    .item-bg-login{
        padding: 30px;
    }
    p.paragraph {
        width: 100%;
    }

    .editprofileimage {
        position: relative;
        width: 166px;
        margin: auto;
    }
    .editprofileimage img {
        width: 100%;
        border-radius: 50%;
        height: auto;
        border: 2px solid #b9b9b9;
    }
    nav.header.navbar.navbar-expand-lg.navbar-light {
        background: #051f87;
        padding: 0px 0px;
    }
    .header .nav-link {
        font-size: 15px;
        padding: 5px 7px !important;
      }
      .btn-outline-success {
        font-size: 18px;
        padding: 8px 15px;
        border: 1px solid;
    }
    .header .btn.btn-primary {
        font-size: 17px;
        padding: 8px 15px;
    }
    .landing-header-logo {
        width: 180px;
    }
    .second.card {
        margin: 77px auto;
        height: 321px;
        padding: 0px;
      
    }
    .second.card.thirdcard {
        margin-top: 170px;
        width: 368px;
        right: 5%;
    }
    .second.card.cardone {
        margin-top: 170px;
        width: 368px;
        left: 12%;
    }
    .headingcenter {
        margin-top: 13px;
    }
    .padding {
        padding: 100px 0px;
    }
    .paragraphtext {
        padding: 0 229px;
        margin-top: 50px;
    }
    .second.card.padding-section {
        box-shadow: none;
        padding: 10px;
        height: 400px;
    }
    .section-auction h1.auction-img {
        font-size: 76px;
        letter-spacing: 6px;
        line-height: 0.5;
    }
    h1.pt-4.pb-4.text-regiter {
        font-size: 24px;
    }
    .paragraphtext {
        padding: 0px 180px;
        margin-top: 0px;
    }
    .image_one{
        position: inherit;
    }
}

@media only screen and (max-width: 576px) {
  
    .group-sec .total.amount {
      position: inherit;
      left: 0;
    }
    footer.landing {
        padding: 23px 3px;
        text-align: center;
    }
    .mob-footer{
        display: block;
      }
      .desktop-footer{
        display: none;
      }
      .table-transaction {
        padding: 0px 20px 10px;
        position: relative;
    }
    .title2.text-left {
        margin: 0px;
    }
    .paragraphtext {
        margin-bottom: 0px !important;
        padding: 0px 10px;
    }
    .section-auction h1.auction-img {
        font-size: 47px;
    }
    .desk{
        display: none;
       }
       .notification-area .Notification-Date {
        top: 10px;
    }
    .banner-item-About-us {
        height: 300px;
    }
    .banner-item-About-us h2 {
        font-size: 20px;
    }
    .banner-item-About-us h4 {
        font-size: 14px;
        line-height: 23px;
    }
    .banner-item-how-it-work {
        background-position: 100% 74px;
        height: 300px;
    }
    .banner-item-Manager-tips{
        height: 300px;
    }
    .banner-item-Faq {
        height: 300px;
        background-position: 100% 74px;
    }
    .banner-item-contact-us{
        height: 300px;
    }
    .text-area h1 {
        font-size: 20px;
    }
  
    p.paragraph {
        width: 100%;
        font-size: 14px;
    }
    .auction-manager {
        width: inherit !important;
    }
    .subscription_heading.ms-4.quries {
       display: none;
    }
   .group_data_table .search-input{
    position: inherit;
    right: 0;
    padding: 10px;
   }
   .notification-icon {
    font-size: 20px;
    margin-right: 0px;

}
.subscription_box-inner p:first-child{
    min-width: inherit;
}
.subscription_box-inner h4{
    min-width: inherit;
}
.subscription {
    padding: 20px;
    width: 100%;
}
.subscription_box-inner {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    margin-bottom: 12px;
}
p + p {
    margin-top: 0px;
}
.topbar .profile-image {
    margin: 0px 13px 0px 6px;
}
   .bid-search.filter{
    width: auto;
   }
   .search-input.filter{
    width: auto;
   }
    .dropdown-manager-approve {
        position: inherit;
     }
     .tab-list-item.player {
        margin-top: 10px;
    }
    .sidebar.mystyle .sidebar-links{
        display: block;
    }
    .sidebar .sidebar-links{
   display: none;
    }
    .second.card {
        margin: 39px auto;
        width: 100%;
    }
    .form-contnet .logo-mob-img{
        width: 200px;
        display: block;
        }
    .second.card h1 {
        font-size: 17px;
    }
    .bg-promocode-btn{
        margin-top: 10px;
    }
    .price-value {
        font-size: 22px;
        margin-left: 11px;
    }
    .btn-outline-success {
        margin-left: 14px !important;

    }
    .header .btn.btn-primary {
        margin-left: 14px !important;
    }
    .rhm-rn-navigation__button {
        padding: 0 20px !important;
    }
    .carousel-control-next {
        right: -30px !important;
        top: 64px !important;
        width: 40px;
        height:40px;
    }
    .carousel-control-prev {
        left: -30px !important;
        top: 64px !important;
        width: 40px;
        height:40px;
    }
    .heading-how-itswork {
        color: #004299;
        font-size: 18px;
        line-height: 22px;
    }
    .item-bg-login {
        display: none;
    }
    .item {
        height: 100vh;
    }
    .item-list {
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 15px;
    }
    .dashboard-content {
        margin-right: 3px;
    }
    .border-custom{
        padding-left: 0px;
        border: none;
    }
    .profile-sec.card{
        padding: 23px !important;
    }
    }


    /***************chart*************/

    .doughnut-chart-container .canvasjs-chart-canvas{
        width: 300px !important;
        height: inherit !important;
   
    }
 
    /* Responsive Styles */
    @media (max-width: 1900px) {
        .doughnut-chart-container .canvasjs-chart-canvas {
            width: 100% !important;
        }
    }
  