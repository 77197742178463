@import url("https://fonts.cdnfonts.com/css/ethnocentric");
@import url("https://fonts.cdnfonts.com/css/ethnocentric");

@font-face {
  font-family: Helvetica;
  src: local("Helvetica"), ur("../../../../public/font/Helvetica.ttf");
}

* {
  font-family: Helvetica;
}

.group-reg {
  display: flex;
}

.form-contnet {
  text-align: center;
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form-contnet .heading {
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
  color: #cecece;
}

.form-contnet p {
  color: #7d7a7a;
  font-size: 16px;
  font-weight: 400;
}

.input-checkbox[type="checkbox"]:enabled:checked {
  accent-color: #303030;
}

.Login-form {
  margin-top: 25px;
}

.Login-form .form-label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
}

.Login-form select {
  height: 47.63px;
  background: #f6f6f6;
  border-radius: 7px;
}

.Login-form input {
  height: 47.63px;
  background: #f6f6f6;
  border-radius: 7px;
  width: 25px;
  margin-right: 10px;
}

.Login-form .rememberInput {
  display: flex;
  align-items: center;
  color: #cecece;
  margin-top: 10px;
}

.Login-form .checkInput input {
  width: 25px;
  height: 25px;
}

.Login-form .checkInput .form-check {
  display: inline-flex;
  align-items: center;
}

.Login-form .checkInput .form-check label {
  margin-left: 10px;
}

.Login-form .select-option {
  color: #757575;
  font-size: 15px;
}

.Login-form input::-moz-placeholder {
  color: #757575;
  font-size: 15px;
}

.Login-form input::placeholder {
  color: #757575;
  font-size: 15px;
}

.Login-form .input-group {
  margin-bottom: 20px;
  position: relative;
}

.Login-form .email-input {
  padding-left: 50px;
  background: #303030;
  border: 1px solid #585858;
  color: #fff;
  border-radius: 7px !important;
}

.Login-form .email-input::-moz-placeholder {
  color: #fff;
}

.Login-form .email-input::placeholder {
  color: #fff;
}

.otp-input {
  width: 82px;
  height: 70px;
  margin: 0px 10px 0px 10px;
  background: #f6f6f6;
}

.inner-item h3 {
  color: #cecece;
  font-size: 22px;
}

.registration {
  margin-top: 25px;
}

.registration .form-label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.registration select {
  height: 47.63px;
  border-radius: 7px;
}

.registration input {
  height: 47.63px;
  border-radius: 7px !important;
  background: #303030 !important;
  border: 1px solid #585858 !important;
  color: #fff !important;
}

.registration .select-option {
  color: #fff;
  font-size: 15px;
  background: #303030;
  border-color: #383737;
}

.registration input::-moz-placeholder {
  color: #757575;
  font-size: 15px;
}

.registration input::placeholder {
  color: #757575;
  font-size: 15px;
}

.registration .input-group {
  margin-bottom: 20px;
}

.registration .submit {
  width: 100%;
  background: #043bc7;
  height: 50px;
  margin-top: 20px;
}

.registration .submit_button {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.heading-get-started {
  font-size: 40px;
}

.registration input::-moz-placeholder {
  color: #fff;
}

.registration input::placeholder {
  color: #fff;
}

.registration input[type="checkbox"]:enabled:checked {
  accent-color: #303030;
}

.submit {
  width: 100%;
  background: #043bc7;
  height: 50px;
  margin-top: 20px;
  border: 0px;
}

.submit:hover {
  background: #043bc7;
}

.canvasjs-chart-credit {
  display: none !important;
}

.item-bg-login {
  width: 50%;
  background-image: url("/public/image/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-bg-login h1 {
  color: #fff;
  text-align: center;
  font-family: "Ethnocentric";
  font-style: italic;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  text-shadow: 7px 9px 9px rgba(6, 6, 6, 0.7215686275);
}

.item-bg-login p {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  width: 68%;
  margin: 20px auto;
}

.item-bg-login .banner-content {
  width: 85%;
  margin: 0 auto;
}

.item-bg {
  width: 50%;
  background-image: url("/public/image/background.png");
  background-size: cover;
}

.item-bg h1 {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin: 50% 0 auto;
}

.item-bg p {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  width: 68%;
  margin: 20px auto;
}

.item-bg .banner-content {
  width: 85%;
  margin: 0 auto;
}

.persion-icon {
  position: absolute;
  top: 13px;
  z-index: 99;
  left: 12px;
  font-size: 24px;
  color: #fff;
}

.persion-icon.lock path {
  stroke: #fff;
}

.eye-icon {
  position: absolute;
  top: 5px;
  z-index: 99;
  right: 42px;
  font-size: 24px;
  color: #a5a5a5;
  cursor: pointer;
}

.password-input {
  margin-bottom: 5px !important;
}

.inner-item {
  width: 100%;
}

.full-contnet {
  margin-left: 20px !important;
}

.text-right {
  text-align: right;
  color: #043bc7;
}

.view-List {
  color: #767575;
}

.item::-webkit-scrollbar {
  width: 5px;
}

.item::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #7e7e7e;
}

.dnsAhI {
  overflow: inherit !important;
  white-space: inherit !important;
  text-overflow: inherit !important;
  font-weight: bold;
}

.item {
  width: 50%;
  padding: 0px 180px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  height: 800px;
  background: #151821;
}

.item .account-para {
  color: #7d7a7a;
  font-size: 18px;
  text-align: center;
}

.item .account-para1 {
  color: #131313;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 100;
  margin-top: 10px;
}

.item .account-para1 a {
  text-decoration: none;
  color: #043bc7;
}

.item .account-para1 a:hover {
  text-decoration: none;
  color: #043bc7;
}

.item .account-para a {
  text-decoration: none;
  color: #043bc7;
}

.ChangePass {
  background: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  height: 76vh;
}

.topbar {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.topbar .profile-image {
  width: 45px;
  border-radius: 100%;
  height: 45px;
  margin: 0px 15px 0px 0px;
}

.topbar .notification {
  font-size: 22px;
  color: #043bc7;
}

.topbar .innetr-group .group-top-bar {
  display: flex;
  align-items: center;
  margin-left: 72px;
}

.topbar .icon-menue {
  margin-left: 66px;
  cursor: pointer;
}

.topbar .dropdown-la {
  color: #043bc7;
  font-size: 18px;
  margin-right: 20px;
}

.topbar .dropdown-toggle {
  border: none;
}

.topbar .dropdown-menu.show {
  display: block;
  margin-top: 16px;
}

.sidebar {
  width: 250px;
  background: #043bc7;
  transition: 0.5s;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  padding-top: 12px;
  z-index: 999;
}

.sidebar .navbar li {
  margin: 3px 10px;
  border-radius: 10px;
  color: #fff;
  width: 100%;
}

.sidebar .navbar li svg {
  margin-right: 10px;
}

.sidebar .navbar .active {
  background: #fff;
  color: #043bc7;
}

.sidebar .profile-icon path {
  fill: #fff;
}

.dasboard-group {
  background: #dfe6fb;
}

.dasboard-group {
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
}

.sidebar.mystyle {
  width: 0;
  transition: 0.5s;
}

.sidebar.mystyle ul {
  opacity: 0;
}

.group-form {
  position: relative;
  margin-left: 20px;
}

.group-form .icon-search {
  position: absolute;
  top: 13px;
  left: 9px;
}

.group-form input {
  padding-left: 40px;
  background: #ededed;
  width: 320.092px;
  height: 44px;
}

.dashboard-content {
  margin: 20px 20px 0px;
  margin-left: 270px;
  padding-top: 70px;
  transition: 0.5s;
  position: relative;
  flex-grow: 1;
}

.dashboard-content .heading-dash {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 10px;
  line-height: 0px;
}

.dashboard-content .group-card {
  display: flex;
}

.dashboard-content .group-card .section-box {
  width: 100%;
  margin: 0px 10px;
  border-radius: 5px;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  align-items: center;
}

.dashboard-content .group-card .section-box .item-1 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #2db441;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-1 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-2 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #043bc7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-2 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-3 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #f32f45;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-3 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-4 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #c98603;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-4 img {
  width: 20px;
}

.dashboard-content .group-card .section-box .content {
  margin-left: 21px;
  margin-top: 20px;
}

.dashboard-content .group-card .section-box .content h2 {
  font-weight: 700;
  color: #403f3f;
}

.dashboard-content .group-card .section-box .content p {
  color: #797777;
  font-size: 14px;
}

.orgnization-sec {
  margin: 20px 0px 0px 0px;
  padding: 20px;
}

.orgnization-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.revenue-sec {
  margin: 20px 0px 0px 0;
  padding: 20px 20px 39px;
}

.revenue-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.err-message {
  width: 100%;
  color: #dc3545;
  margin-top: 10px;
}

.date-section {
  text-align: center;
  color: #fff;
}

.ofEne {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 14px;
}

.success-message {
  width: 300px;
  position: absolute;
  right: 0;
}

.vl {
  border-left: 1px solid #c8c4c4;
  height: 242px;
  left: 57%;
  position: absolute;
  padding-left: 14px;
  top: 80px;
}

.group-sec {
  position: relative;
  color: #043bc7;
}

.group-sec .total.amount {
  position: absolute;
  top: 41%;
  left: 24%;
  text-align: center;
  color: #878282;
}

.group-sec .amount-text {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.group-sec .item1 {
  background: #ffffff;
  position: absolute;
  width: 45px;
  height: 45px;
  box-shadow: 0px 1px 4px -1px #000;
  top: -2px;
  left: 121px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .item2 {
  background: #ffffff;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 109px;
  left: 0px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px -1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .item3 {
  background: white;
  width: 45px;
  position: absolute;
  height: 45px;
  top: 188px;
  box-shadow: 0px 1px 4px -1px #000;
  right: 63px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .percen-contnet {
  right: 49px;
  transition: 0.5s;
}

.item4 {
  background: #ffffff;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 3px;
  left: 59px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px -1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total.Balance {
  position: absolute;
  top: 43%;
  left: 18%;
  font-weight: 700;
  text-align: center;
  color: #043bc7;
}

.placeBid-button {
  background: #043bc7;
  color: #fff;
  padding: 13px 10px;
  border: none;
  width: 170px;
  border-radius: 26px;
}

.right-text {
  text-align: right;
}

.auction-list-h {
  font-weight: 600;
}

.item5 {
  background: white;
  width: 45px;
  position: absolute;
  height: 45px;
  top: 212px;
  box-shadow: 0px 1px 4px -1px #000;
  right: 151px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item {
  margin-left: 20px;
  margin-top: 90px;
}

.image-auction {
  position: absolute;
  top: 32%;
  left: 28%;
}

.bg-warn {
  background: #f2eddb;
  padding: 10px 20px;
  border-radius: 22px;
  color: #c29e2d;
}

.orgnization-table tr td {
  padding: 1rem;
  font-size: 15px;
  color: #797777;
}

.orgnization-table tr th {
  color: #2d2d2d;
  font-size: 16px;
}

.bg-succ {
  background: #c1f6c9;
  color: #2db441;
  padding: 10px 20px;
  border-radius: 22px;
}

.bg-dang {
  background: #ffdce0;
  color: #f32f45;
  padding: 10px 20px;
  border-radius: 22px;
}

.bid-table tr td {
  padding: 1rem;
  font-size: 15px;
  color: #797777;
}

.bid-table tr th {
  padding: 1rem;
  font-size: 15px;
  color: #2d2d2d;
  white-space: nowrap;
}

.text-hide {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.payment-plan {
  text-align: center;
  min-height: calc(100vh - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-plan h1 {
  font-size: 40px;
  font-weight: 700;
  color: #222222;
}

.payment-plan h1 span {
  color: #043bc7;
}

.payment-plan .para-text {
  color: #716d6d;
  font-size: 16px;
  margin-bottom: 50px;
}

.item-col.active {
  background: #043bc7;
  color: #fff;
}

.item-col.active .table> :not(caption)>*>* {
  color: #fff;
}

.item-col.active .btn-upgarde {
  background: #fff;
  color: #043bc7;
  font-weight: 700;
  padding: 13px 40px;
}

.item-col:hover {
  background: #043bc7;
  color: #fff;
  transition: 0.5s ease-in-out;
}

.item-col:hover .table tr td {
  color: #fff;
}

.item-col:hover .btn-payment {
  background: #fff;
  color: #043bc7;
  font-weight: 700;
  border: #fff;
}

.item-col:hover .icon-check {
  color: #fff;
}

.item-col {
  background: #f4f7ff;
  padding: 50px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  width: 366px;
  margin: 0 auto;
}

.item-col .icon-check-active {
  color: #fff;
  font-size: 25px;
  margin-right: 16px;
  font-weight: bold;
}

.item-col .icon-check {
  font-size: 25px;
  margin-right: 16px;
  font-weight: bold;
  color: #043bc7;
}

.item-col h3 {
  text-align: center;
  font-weight: 700;
  line-height: 1.6;
}

.item-col h3 span {
  font-size: 40px;
}

.item-col .table> :not(caption)>*>* {
  background: none;
  border: none;
  color: #000;
  text-align: left;
}

.item-col .btn-payment {
  background: #043bc7;
  color: #fff;
  text-align: center;
  padding: 13px 40px;
}

.icon-back {
  font-size: 38px;
  margin-left: 59px;
  cursor: pointer;
}

.email-input {
  padding-left: 50px;
}

.active a {
  text-decoration: none;
}

.account-para1 span {
  cursor: pointer;
}

.jJhCqB div:first-child {
  text-overflow: inherit !important;
  overflow: inherit !important;
}

.iIVLBG {
  min-width: 200px !important;
}

.koyZDM {
  background: #fff;
}

.cfvlby {
  background-color: #dfe6fb !important;
  border-bottom-style: solid;
}

.hGkspH {
  min-width: inherit !important;
}

.jTPWeN {
  padding: 20px;
}

.desk {
  display: block;
}

.mob {
  display: none;
}

.mYSul {
  border: 1px solid #dededf;
}

.cxFtRn {
  border-top: inherit !important;
}

.jiAnER {
  min-height: 60px !important;
}

.item-list {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

.group-list1 {
  display: flex;
  align-items: center;
}

.group-list1 .image-icon {
  background: #f32f45;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon1 {
  background: #2db441;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon2 {
  background: #fb8b05;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon3 {
  background: #043bc7;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon4 {
  background: #f9c114;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .content {
  margin-left: 20px;
}

.group-list1 .content h2 {
  font-weight: bold;
  color: #403f3f;
  font-size: 35px;
}

.group-list1 .content p {
  color: #797777;
  font-size: 14px;
  font-weight: 400;
}

.Bidder-sec {
  padding: 20px;
  overflow: auto;
}

.Bidder-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.auctiontabs.player .bzRuyo {
  display: none !important;
}

.group_data_table.sport {
  padding: 20px;
}

.group_data_table.tournament-list {
  padding: 20px;
}

.group_data_table.sec-announcement {
  padding: 20px;
}

.group_data_table.admin {
  padding: 20px;
}

.group_data_table.announcement {
  padding: 20px;
}

.group_data_table.auction-list {
  padding: 20px;
}

.group_data_table.team-list {
  padding: 20px;
}

.jbrMhe {
  opacity: 0;
}

.chart-pie {
  border: 40px solid #043bc7;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
}

.grop-section {
  position: relative;
}

.icon-back {
  position: absolute;
  top: 0;
}

.icon-back a {
  color: #fff;
}

.chart h3 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.list-circle {
  background: #043bc7;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #767575;
}

.list-circle-pro {
  background: #f32f45;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.list-circle-up {
  background: #f9c114;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.group-list-item {
  position: relative;
}

.number-List {
  position: absolute;
  top: 2px;
  right: 103px;
  background: #fff;
  width: 45px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.number-List2 {
  position: absolute;
  top: 188px;
  right: 77px;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.number-List3 {
  position: absolute;
  top: 137px;
  left: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.Chart-sec {
  min-height: 300px;
  padding: 20px;
  position: relative;
}

.heading_sumary {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.icons-sidebar {
  font-size: 24px;
}

.form-check-input {
  width: 25px;
  height: 25px;
}

.form-check {
  align-items: center;
  display: flex;
}

.form-check-label {
  margin-left: 5px;
}

.modal-registration>.modal-header {
  display: none;
}

.border-custom {
  border-left: 1px solid #b9b9b9;
  padding-left: 60px;
}

.cancel-btn {
  background: #ababab;
  color: #fff;
  border: none;
  width: 145px;
  height: 49px;
  border-radius: 5px;
}

.update-btn-prof {
  width: 145px;
  height: 49px;
  margin-left: 10px;
  background: #043bc7;
}

.profile-sec h5 {
  color: #043bc7;
}

.dashboard-man {
  margin-top: 20px;
}

.profile-sec {
  margin-top: 20px;
}

.icons-check {
  background: #cfd9f1;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  margin: auto;
}

.icons-check-bell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  margin: auto;
}

.group-modal {
  position: relative;
}

.button_back_login {
  background: #043bc7;
  width: 82%;
  margin: 10px 0px 20px 0px;
}

.button-ok {
  background: #043bc7;
  margin: 10px 0px 20px 0px;
  padding: 12px 50px;
}

.pagenotfound-section {
  background-image: url("/public/image/Page_not _found.png");
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pagenotfound-section h1 {
  color: #043bc7;
  font-size: 10rem;
  margin-bottom: 59px;
}

.pagenotfound-section h3 {
  color: #5b5b5b;
  font-size: 32px;
}

.pagenotfound-section p {
  color: #4b4b4b;
  font-size: 20px;
}

.pagenotfound-section .back_button {
  background: #043bc7;
  padding: 15px 85px;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 20px;
}

.permonthtext {
  font-size: 18px !important;
}

.icons-check-bell img {
  width: 98px;
  margin-top: 17px;
}

.icon-dash path {
  stroke: #fff;
}

.sidebar-links a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.home-icon-top {
  font-size: 23px;
  color: #315ed1;
  cursor: pointer;
}

.sidebar-links.active a {
  color: #043bc7 !important;
  background: #fff;
}

.sidebar-links.active a:hover {
  color: #043bc7 !important;
  background: #fff;
}

.sidebar-links.active a .icon-dash path {
  stroke: #043bc7;
}

.sidebar-links.active a path {
  fill: #043bc7;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #043bc7;
  color: #fff;
  border: none;
}

.nav-tabs {
  border: none;
  justify-content: end;
}

.nav-link {
  background: #dfe6fb;
  border: none !important;
  color: #000;
}

.list-item ul li {
  line-height: 2;
}

.status-request {
  font-weight: 700;
  font-size: 30px;
}

.btn-close-demo {
  margin: 10px 0px 20px 0px;
  padding: 12px 50px;
}

.group_data_table {
  background: #fff;
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  flex: 1 0 auto;
}

.group_data_table .search-input {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 99;
  width: 300px;
}

.card {
  border: 0px;
}

.mYSul {
  border-radius: 5px;
}

.rdt_Pagination {
  border-radius: 8px;
}

.select-option {
  height: 46px;
}

.btn-Save {
  padding: 10px 40px;
  background: #043bc7;
}

.btn-Cancel {
  padding: 10px 40px;
  background: #ababab;
  border: #ababab;
}

.btn-Cancel:hover {
  background: #898787;
  border: #898787;
}

.auction-heading {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}



.editprofileimage {
  position: relative;
  width: 300px;
  margin: auto;
}

.editprofileimage img {
  width: 250px;
  border-radius: 50%;
  height: 250px;
  border: 2px solid #043bc7;
}

.editprofileicon {
  position: absolute;
  top: 44px;
  right: 13px;
  width: 40px;
  height: 40px;
  background: #043bc7;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editprofileicon input {
  display: none;
}

.text-color-format {
  color: #afb2bb;
}

.item-sec {
  background: #fff;
  padding: 15px 30px 15px;
  border-radius: 5px;
  position: relative;
}

.item-sec .item-1 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #2db441;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-sec .content {
  text-align: center;
}

.item-sec .group-content {
  display: flex;
}

.item-sec .vl {
  border-left: 1px solid #dbdbdb;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 53px;
}

.item-sec h4 {
  color: #403f3f;
  font-size: 20px;
  margin-bottom: 15px;
}

.item-2 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #043bc7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputCheck {
  height: 25px !important;
  width: 25px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.profile-form-sec input {
  height: 46px;
}

.react-datepicker-wrapper {
  width: 100%;
}


.registration_tournament .form-control {
  height: 46px;
}

.sec-item {
  background: #fff;
  border-radius: 8px;
}

.item-link {
  width: 100%;
  display: block;
  padding: 3px 13px;
  color: #000;
  text-decoration: none;
}

.imageselected {
  width: 100px;
}

.Toastify__close-button>svg {
  margin-top: 16px !important;
}

.icon-bike {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.select-sport {
  height: 46px;
}

.add-date {
  position: relative;
}

.date-picker {
  position: absolute;
  top: 15px;
  right: 34px;
}

.brackets-btn {
  width: 145px;
  height: 49px;
  background: #525252;
  border-color: #4e4c4c;
}

.group-header {
  position: relative;
}

.Login-form-close {
  position: absolute;
  top: 0;
  right: 0;
}

.visible-con {
  visibility: hidden;
}

.banner-content img {
  width: 450px;
}

.heading-auction {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
}

/****************/
.image-chosse {
  width: 100%;
  background: #f5f4f4;
  padding: 10px;
  width: 55%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  height: 200px;
  border: 2px dotted #a9a8a8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-camera {
  font-size: 2rem;
  color: #8f8f8f;
}

.input-check-group {
  display: flex;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.input-check-group {
  font-size: 1.1rem;
}

.image-upload-mess {
  font-size: 1.1rem;
  color: #898383;
}

.active-auction {
  background: #c1f6c9;
  padding: 10px 26px;
  border: none;
  border-radius: 50px;
  color: #2db441;
}

.btn-aucion {
  background: none;
  border: none;
  font-size: 1.6rem;
  font-weight: bolder;
}

.SportNameAdd {
  border: 1px solid #cdcdcd;
  padding: 5px 8px;
  margin-top: 10px;
  border-radius: 8px;
}

.select-option.tournament {
  height: 45px;
}

.label {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.input-game {
  border: 1px solid #cdcdcd;
  height: 45px;
  padding: 10px;
  border-radius: 10px;
}

.table-team th {
  background: #e5edff;
}

.inactive-auction {
  background: #ffdfdf;
  color: #ff0000;
  padding: 10px 26px;
  border: none;
  border-radius: 50px;
}

.button-create-auction {
  background: #043bc7;
  border-color: #043bc7;
}



.imageAuction {
  width: 150px;
}

.winner-distribution {
  color: #043bc7;
  border-bottom: 2px solid;
  font-weight: 600;
  margin: 25px 0px 25px 0px;
  font-size: 18px;
}

.winner-percentage {
  border: 1px solid #c3c3c3;
  margin-top: 25px;
  padding: 20px;
  border-radius: 10px;
}

.text-color {
  color: #0e751e;
  font-weight: 600;
  margin-bottom: 8px;
}

.icon-plus {
  font-size: 1.8rem;
}

.Login-form .email-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.registration input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.line {
  border: 1px solid #464444;
}

.registration input::-moz-placeholder {
  font-size: 15px;
}

.registration input::placeholder {
  font-size: 15px;
}

.text-color-la {
  color: #cecece;
  position: relative;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #303030;
  border: 1px solid #585858;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
/* Show the checkmark when checked */
input:checked~.checkmark:after {
  display: block;
}

.checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.input-checkbox {
  height: inherit !important;
}

input:checked~.checkmark {
  background-color: #043bc7;
  border: 1px solid #1656fb;
}

button#justify-tab-example-tab-profile {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  font-size: 14px;
}

#justify-tab-example-tab-Amount {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  font-size: 14px;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-grow: inherit;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  padding: 8px 20px 8px 20px;
}

.sort-status {
  width: 70%;
}



.submit-btn {
  background: #043bc7;
  padding: 8px 30px;
}

.text-danger {
  color: #ff0000 !important;
}

.text-success {
  color: #0e751e !important;
}

.close-btn {
  padding: 8px 30px;
}

.submit-btn:hover {
  background: #043bc7;
}

.form-check-input:checked {
  background-color: #043bc7;
  border-color: #043bc7;
}

.eEletC {
  border-radius: 8px;
}

.group-chart {
  position: relative;
}

.text-color-tab {
  color: #000;
}

.PhoneInputInput {
  flex: auto !important;
  min-width: inherit !important;
  width: 45px;
  border: inherit;
  background: inherit;
}

.group-ph {
  position: relative;
}

.phone-number-input {
  padding-left: 114px;
}

.PhoneInputCountryIcon--border {
  background-color: inherit !important;
  box-shadow: inherit !important;
}

.PhoneInput {
  display: flex;
  align-items: center;

  position: absolute;
  margin-left: 11px;
  background: inherit;
  border-radius: 1px solid;
  border-right: 1px solid #d3d3d3;
}
.phoneAdmin{
  top:10px;
}
.total.Count.mob{
  display: block;
}

.PhoneInputCountry {
  line-height: 0px !important;
}

input.PhoneInputInput:focus-visible {
  outline: 0;
}

.heading-dash {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.bg-gray-view {
  background-color: #dfe6fb !important;
}

.dotsicon {
  font-size: 1.5rem;
  color: #0c266c;
}

.viewButton {
  border: 0;
  background: #bfd1ff;
  color: #043bc7;
  padding: 5px 0;
  border-radius: 50px;
  width: 88px;
}

.viewButton:hover {
  border: 0;
  background: #bfd1ff;
  color: #043bc7;
  border-radius: 50px;
}

/*****QA release after chanes*******/
.placeBid-sec {
  background: #043bc7;
  text-align: center;
  padding: 35px;
  border-radius: 10px;
  margin-top: 22px;
}

.currentBid {
  color: #043bc7;
  font-weight: 600;
}

.remaing-time {
  border-left: 1px solid;
}

.group-auctionBid-date {
  border: 1px solid;
  color: #fff;
  width: 300px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 31px;
}

.ImageAuction_bid {
  border: 10px solid #fff;
  border-radius: 10px;
}

.Auction-Date input {
  height: 45px;
}

.Auction-Date {
  width: 100%;
}

.sidebar-links a:hover {
  color: #fff !important;
  color: var(--rs-text-link-hover);
  text-decoration: none !important;
}

.sidebar-links a:focus {
  color: var(--rs-text-link-hover) !important;
  text-decoration: none !important;
}

.rs-picker {
  width: 100%;
}

.rs-picker-date>.rs-input-group.rs-input-group-inside .rs-input {
  height: 45px !important;
}

.image-upload-update {
  position: absolute;
  top: 240px;
  left: -172px;
  width: 179px;
}

.border-right {
  position: absolute;
  border-right: 1px solid #bbb2b2;
  left: 50%;
  top: 77px;
  height:544px;
}

.placeBid-sec h2 {
  font-size: 30px;
}

.free-subscription {
  border: none !important;
}

.dkIDck {
  background-color: #dfe6fb !important;
}

.QGrfW {
  border: 1px solid #d7d7d7 !important;
}

.kZOmcs {
  padding: 20px !important;
}

.dfpYxD {
  border: none !important;
}

.rs-picker-popup.rs-picker-popup-date {
  z-index: 9999 !important;
}



.xcuttaTakeinput {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #d7d7d7;

}

.xcuttaTakeinput:focus {
  outline: 0;
}

.auctionImage {
  max-height: 300px;
  min-height: 300px;
}

.group-image-auction {
  position: relative;
}

.LiveButton {
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 7px 13px;
  font-size: 18px;
  font-weight: 600;
}

.back-count {
  background: #cecece;
  padding: 18px;
  // width: 186px;
  // display: flex;
  // align-items: center;
}

.dWYBbN {
  color: rgb(0, 0, 0) !important;
  background-color: #ebebeb !important;
}

.VkrsJ {
  color: rgb(0, 0, 0) !important;
  background-color: #ebebeb !important;
}

.hide-content {
  display: none;
}

.gkXCXi {
  padding: 0px !important;
}

.gkXCXi {
  border-bottom: 1px solid #fff;
}

.input-editable {
  border: none;
  background: none;
  color: #11a116;
  font-size: 1rem;
}

.input-editable:focus {
  border: 1px solid;
}

/*----------03/04-24------------------*/
.viewButton {
  background: #bfd1ff;
  color: #043bc7;
}

.edit-icon {
  color: #4caf50;
}

.btn-edit {
  padding: 5px 20px;
  border-radius: 5px;
}

.tablebtn {
  border: 0;
  padding: 5px 16px;
  border-radius: 50px;
  font-weight: 600;
  align-items: center;
  display: flex;
  min-height: 32px;
  width: 100%;
  white-space: nowrap;
}

.viewButton svg {
  font-size: 22px;
  margin-right: 0;
}

.active_text {
  color: #2db441;
  font-weight: 600;
  margin: 0;
}

.sort-status {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}

.close-btn {
  background: #043bc7;
  border: 0;
  position: absolute;
  top: -21px;
  right: -21px;
  border-radius: 66%;
  height: 43px;
  width: 43px;
  padding: 10px;
}

.close-btn:hover {
  background: #043bc7;
}

/*-----tabs---------------*/
.tabs-auction .nav-tabs {
  border: none;
  justify-content: start;
  border-bottom: 1px solid #e5e4e4;
}

.dSMYRw {
  display: none;
}

.QqzfF {
  border: 1px solid #ddd7d7;
}

.dcCEdk {
  padding: 20px;
}

.iwfvUU {
  background-color: #dfe6fb !important;
}

.tabs-auction .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent;
  color: #043bc7;
  font-weight: 600;
  border-bottom: 2px solid #043bc7 !important;
}

.tabs-auction .nav-link {
  background: transparent;
  border: none !important;
  color: #000;
}

.tabs-box {
  padding: 20px;
}

.auctiontabs .jTPWeN {
  padding: 0px 0;
}

.search-input.filter.auction-filter {
  display: flex;
  align-items: center;
  position: unset;
  float: right;
  justify-content: end;
}

.active-status {
  background: #c1f6c9;
  color: #2db441;
}

.placebid {
  background: #043bc7;
  color: #fff;
  font-weight: 400;
}

.table-team.table-bordered> :not(caption)>*>* {
  border-width: 0;
  font-size: 14px;
  padding: 16px;
}

.modal-footer {
  padding: 0;
  border-top: 0;
}

.notification {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
}

.notification-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.notification-right h1 {
  font-size: 16px;
  font-weight: 600;
  color: #2c2828;
}

.notification-right p {
  font-size: 13px;
  color: #646060;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #caaeed;
  color: #fff;
}

.timeago {
  background: #f3f3f3;
  height: 32px;
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: #545050;
}

.notification_box {
  padding: 20px;
}

.subscription {
  padding: 20px;
  width: 40%;
}

.subscription_heading {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 10px;
}

.subscription_box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}

.subscription_box-inner {
  display: flex;
}

.subscription_box-inner h4 {
  font-size: 26px;
  font-weight: 600;
  min-width: 400px;
}

.subscription_box-inner p:first-child {
  min-width: 400px;
  color: #2f2f2f;
}

.subscription_box-inner-btn {
  display: flex;
}

.subscription_box-inner-btn button:first-child {
  background: #043bc7;
  color: #fff;
  margin-right: 20px;
}

.subscription_box-inner-btn button:last-child {
  background: #0e751e;
  color: #fff;
}

.subscription_box-inner button {
  background: #0e751e;
  color: #fff;
  border-radius: 20px;
  padding: 0 28px;
  border-color: #0e751e;
}

.table-transaction {
  padding: 10px 20px 10px;
  position: relative;
}

span.status-com {
  background: #0e751e;
  color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
}

.subscription.chart {
  padding: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.apexcharts-legend-text {
  padding-left: 15px;
  margin-left: -5px !important;
  padding: 0 30px;
}

.dropdown-icon.dropdown .btn {
  background-color: #fff;
  border: 0;
}

.dropdown-icon.dropdown .dropdown-toggle::after {
  display: none;
}

.block.dropdown-item:focus,
.dropdown-item {
  padding: 10px 16px;
}

.block.dropdown-item {
  color: #fff;
  background-color: #e5808a !important;
  padding: 10px 16px;
  border-radius: 3px;
}

.unblock.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #0e751e !important;
}

.dropdown-menu.show {
  padding: 0;
  border-color: #f3f3f3;
}

.profile-pic2 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #dc3545;
  color: #fff;
  flex-shrink: 0;
}

input#validationCustom02 {
  margin-bottom: 14px;
  /* width: 100px; */
}

.iIfIOa {
  display: none;
}

.sc-fFoeYl.egfKAx {
  display: none;
}

.profile-pic3 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #0e751e;
  color: #fff;
}

.profile-pic4 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #561d0c;
  color: #fff;
}

/***17-06-2024**********/
@font-face {
  font-family: Helvetica;
  src: local("Helvetica"), ur("../../../../public/font/Helvetica.ttf");
}

* {
  font-family: Helvetica;
}

.group-reg {
  display: flex;
}

.form-contnet {
  text-align: center;
}

.form-contnet .heading {
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
  color: #cecece;
}

.form-contnet p {
  color: #7d7a7a;
  font-size: 16px;
  font-weight: 400;
}

.input-checkbox[type="checkbox"]:enabled:checked {
  accent-color: #303030;
}

.Login-form {
  margin-top: 25px;
}

.Login-form .form-label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
}

.Login-form select {
  height: 47.63px;
  background: #f6f6f6;
  border-radius: 7px;
}

.Login-form input {
  height: 47.63px;
  background: #f6f6f6;
  border-radius: 7px;
  width: 25px;
  margin-right: 10px;
}

.Login-form .rememberInput {
  display: flex;
  align-items: center;
  color: #cecece;
  margin-top: 10px;
}

.Login-form .checkInput input {
  width: 25px;
  height: 25px;
}

.Login-form .checkInput .form-check {
  display: inline-flex;
  align-items: center;
}

.Login-form .checkInput .form-check label {
  margin-left: 10px;
}

.Login-form .select-option {
  color: #757575;
  font-size: 15px;
}

.Login-form input::-moz-placeholder {
  color: #757575;
  font-size: 15px;
}

.Login-form input::placeholder {
  color: #757575;
  font-size: 15px;
}

.Login-form .input-group {
  margin-bottom: 20px;
  position: relative;
}

.Login-form .email-input {
  padding-left: 50px;
  background: #303030;
  border: 1px solid #585858;
  color: #fff;
  border-radius: 7px !important;
}

.Login-form .email-input::-moz-placeholder {
  color: #fff;
}

.Login-form .email-input::placeholder {
  color: #fff;
}

.otp-input {
  width: 82px;
  height: 70px;
  margin: 0px 10px 0px 10px;
  background: #f6f6f6;
}

.inner-item h3 {
  color: #cecece;
  font-size: 22px;
}

.registration {
  margin-top: 25px;
}

.registration .form-label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.registration select {
  height: 47.63px;
  border-radius: 7px;
}

.registration input {
  height: 47.63px;
  border-radius: 7px !important;
  background: #303030 !important;
  border: 1px solid #585858 !important;
  color: #fff !important;
}

.registration .select-option {
  color: #fff;
  font-size: 15px;
  background: #303030;
  border-color: #383737;
}

.registration input::-moz-placeholder {
  color: #757575;
  font-size: 15px;
}

.registration input::placeholder {
  color: #757575;
  font-size: 15px;
}

.registration .input-group {
  margin-bottom: 20px;
}

.registration .submit {
  width: 100%;
  background: #043bc7;
  height: 50px;
  margin-top: 20px;
}

.registration .submit_button {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.heading-get-started {
  font-size: 40px;
}

.registration input::-moz-placeholder {
  color: #fff;
}

.registration input::placeholder {
  color: #fff;
}

.registration input[type="checkbox"]:enabled:checked {
  accent-color: #303030;
}

.submit {
  width: 100%;
  background: #043bc7;
  height: 50px;
  margin-top: 20px;
  border: 0px;
}

.submit:hover {
  background: #043bc7;
}

.canvasjs-chart-credit {
  display: none !important;
}

.item-bg-login {
  width: 50%;
  background-image: url("/public/image/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-bg-login h1 {
  color: #fff;
  text-align: center;
  font-family: "Ethnocentric";
  font-style: italic;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  text-shadow: 7px 9px 9px rgba(6, 6, 6, 0.7215686275);
}

.item-bg-login p {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  width: 68%;
  margin: 20px auto;
}

.item-bg-login .banner-content {
  width: 85%;
  margin: 0 auto;
}

.item-bg {
  width: 50%;
  background-image: url("/public/image/background.png");
  background-size: cover;
}

.item-bg h1 {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin: 50% 0 auto;
}

.item-bg p {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  width: 68%;
  margin: 20px auto;
}

.item-bg .banner-content {
  width: 85%;
  margin: 0 auto;
}

.persion-icon {
  position: absolute;
  top: 13px;
  z-index: 99;
  left: 12px;
  font-size: 24px;
  color: #fff;
}

.persion-icon.lock path {
  stroke: #fff;
}

.eye-icon {
  position: absolute;
  top: 5px;
  z-index: 99;
  right: 42px;
  font-size: 24px;
  color: #a5a5a5;
  cursor: pointer;
}

.password-input {
  margin-bottom: 5px !important;
}

.inner-item {
  width: 100%;
}

.full-contnet {
  margin-left: 20px !important;
}

.text-right {
  text-align: right;
  color: #043bc7;
}

.view-List {
  color: #767575;
}

.item::-webkit-scrollbar {
  width: 5px;
}

.item::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #7e7e7e;
}

.item {
  width: 50%;
  padding: 0px 180px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  height: 800px;
  background: #151821;
}

.item .account-para {
  color: #7d7a7a;
  font-size: 18px;
  text-align: center;
}

.item .account-para1 {
  color: #131313;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 100;
  margin-top: 10px;
}

.item .account-para1 a {
  text-decoration: none;
  color: #043bc7;
}

.item .account-para1 a:hover {
  text-decoration: none;
  color: #043bc7;
}

.item .account-para a {
  text-decoration: none;
  color: #043bc7;
}

.ChangePass {
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  height: 76vh;
}

.topbar {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.topbar .profile-image {
  width: 45px;
  border-radius: 100%;
  height: 45px;
  margin: 0px 15px 0px 0px;
}

.topbar .notification {
  font-size: 22px;
  color: #043bc7;
}

.topbar .innetr-group .group-top-bar {
  display: flex;
  align-items: center;
  margin-left: 72px;
}

.topbar .icon-menue {
  margin-left: 66px;
  cursor: pointer;
}

.topbar .dropdown-la {
  color: #043bc7;
  font-size: 18px;
  margin-right: 20px;
}

.topbar .dropdown-toggle {
  border: none;
}

.topbar .dropdown-menu.show {
  display: block;
  margin-top: 16px;
}

.sidebar {
  width: 250px;
  background: #043bc7;
  transition: 0.5s;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  padding-top: 12px;
  z-index: 999;
}

.sidebar .navbar li {
  margin: 3px 10px;
  border-radius: 10px;
  color: #fff;
  width: 100%;
}

.sidebar .navbar li svg {
  margin-right: 10px;
}

.sidebar .navbar .active {
  background: #fff;
  color: #043bc7;
}

.sidebar .profile-icon path {
  fill: #fff;
}

.dasboard-group {
  background: #dfe6fb;
}

.dasboard-group {
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
}

.sidebar.mystyle {
  width: 0;
  transition: 0.5s;
}

.sidebar.mystyle ul {
  opacity: 0;
  display: none;
}

.group-form {
  position: relative;
  margin-left: 20px;
}

.group-form .icon-search {
  position: absolute;
  top: 13px;
  left: 9px;
}

.group-form input {
  padding-left: 40px;
  background: #ededed;
  width: 320.092px;
  height: 44px;
}

.dashboard-content {
  margin: 20px 20px 0px;
  margin-left: 270px;
  padding-top: 70px;
  transition: 0.5s;
  position: relative;
  flex-grow: 1;
}

.dashboard-content .heading-dash {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 10px;
}

.dashboard-content .group-card {
  display: flex;
}

.dashboard-content .group-card .section-box {
  width: 100%;
  margin: 0px 10px;
  border-radius: 5px;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  align-items: center;
}

.dashboard-content .group-card .section-box .item-1 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #2db441;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-1 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-2 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #043bc7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-2 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-3 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #f32f45;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-3 img {
  width: 35px;
}

.dashboard-content .group-card .section-box .item-4 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #c98603;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content .group-card .section-box .item-4 img {
  width: 20px;
}

.dashboard-content .group-card .section-box .content {
  margin-left: 21px;
  margin-top: 20px;
}

.dashboard-content .group-card .section-box .content h2 {
  font-weight: 700;
  color: #403f3f;
}

.dashboard-content .group-card .section-box .content p {
  color: #797777;
  font-size: 14px;
}

.orgnization-sec {
  margin: 20px 0px 0px 0px;
  padding: 20px;
}

.orgnization-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.revenue-sec {
  margin: 20px 0px 0px 0;
  padding: 20px 20px 39px;
}

.revenue-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.err-message {
  width: 100%;
  color: #dc3545;
  margin-top: 10px;
}

.date-section {
  text-align: center;
  color: #fff;
}

.ofEne {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 14px;
}

.success-message {
  width: 300px;
  position: absolute;
  right: 0;
}

.vl {
  border-left: 1px solid #c8c4c4;
  height: 320px;
  left: 57%;
  position: absolute;
  padding-left: 14px;
  top: 80px;
}

.group-sec {
  position: relative;
  color: #043bc7;
}

.group-sec .total.amount {
  position: absolute;
  top: 41%;
  left: 20%;
  text-align: center;
  color: #878282;
}
.total.amount.des{
display: none;
}

.group-sec .Count-text {
  position: absolute;
  top: 83%;
  left: 19%;
  text-align: center;
  color: #878282;
}

.group-sec .total.amount {
  position: absolute;
  top: 41%;
  left: 26%;
  text-align: center;
  color: #878282;
}

.group-sec .Count-text {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.group-sec .total.Count {
  color: #878282;
  left: 45%;
  position: absolute;
  text-align: center;
  top: 43%;
}
.total.Count.des{
  display: none;
}
.group-sec .item1 {
  background: #ffffff;
  position: absolute;
  width: 45px;
  height: 45px;
  box-shadow: 0px 1px 4px -1px #000;
  top: -2px;
  left: 121px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .item2 {
  background: #ffffff;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 109px;
  left: 0px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px -1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .item3 {
  background: white;
  width: 45px;
  position: absolute;
  height: 45px;
  top: 188px;
  box-shadow: 0px 1px 4px -1px #000;
  right: 279px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-sec .percen-contnet {
  right: 49px;
  transition: 0.5s;
}

.list-item {
  margin-left: 20px;
  margin-top: 90px;
}

.image-auction {
  position: absolute;
  top: 32%;
  left: 28%;
}

.bg-warn {
  background: #f2eddb;
  padding: 10px 20px;
  border-radius: 22px;
  color: #c29e2d;
}

.orgnization-table tr td {
  padding: 1rem;
  font-size: 15px;
  color: #797777;
}

.orgnization-table tr th {
  color: #2d2d2d;
  font-size: 16px;
}

.bg-succ {
  background: #c1f6c9;
  color: #2db441;
  padding: 10px 20px;
  border-radius: 22px;
}

.bg-dang {
  background: #ffdce0;
  color: #f32f45;
  padding: 10px 20px;
  border-radius: 22px;
}

.bid-table tr td {
  padding: 1rem;
  font-size: 15px;
  color: #797777;
}

.bid-table tr th {
  padding: 1rem;
  font-size: 15px;
  color: #2d2d2d;
}

.text-hide {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.payment-plan {
  text-align: center;
  min-height: calc(100vh - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-plan h1 {
  font-size: 40px;
  font-weight: 700;
  color: #222222;
}

.payment-plan h1 span {
  color: #043bc7;
}

.payment-plan .para-text {
  color: #716d6d;
  font-size: 16px;
  margin-bottom: 50px;
}

.item-col.active {
  background: #043bc7;
  color: #fff;
}

.item-col.active .table> :not(caption)>*>* {
  color: #fff;
}

.item-col.active .btn-upgarde {
  background: #fff;
  color: #043bc7;
  font-weight: 700;
  padding: 13px 40px;
}

.item-col:hover {
  background: #043bc7;
  color: #fff;
  transition: 0.5s ease-in-out;
}

.item-col:hover .table tr td {
  color: #fff;
}

.item-col:hover .btn-payment {
  background: #fff;
  color: #043bc7;
  font-weight: 700;
  border: #fff;
}

.item-col:hover .icon-check {
  color: #fff;
}

.item-col {
  background: #f4f7ff;
  padding: 50px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  width: 366px;
  margin: 0 auto;
}

.item-col .icon-check-active {
  color: #fff;
  font-size: 25px;
  margin-right: 16px;
  font-weight: bold;
}

.item-col .icon-check {
  font-size: 25px;
  margin-right: 16px;
  font-weight: bold;
  color: #043bc7;
}

.item-col h3 {
  text-align: center;
  font-weight: 700;
  line-height: 1.6;
}

.item-col h3 span {
  font-size: 40px;
}

.item-col .table> :not(caption)>*>* {
  background: none;
  border: none;
  color: #000;
  text-align: left;
}

.item-col .btn-payment {
  background: #043bc7;
  color: #fff;
  text-align: center;
  padding: 13px 40px;
}

.icon-back {
  font-size: 38px;
  margin-left: 59px;
  cursor: pointer;
}

.email-input {
  padding-left: 50px;
}

.active a {
  text-decoration: none;
}

.account-para1 span {
  cursor: pointer;
}

.jJhCqB div:first-child {
  text-overflow: inherit !important;
  overflow: inherit !important;
}

.iIVLBG {
  min-width: 200px !important;
}

.koyZDM {
  background: #fff;
}

.cfvlby {
  background-color: #dfe6fb !important;
  border-bottom-style: solid;
}

/*-------.hGkspH{
    08-04-2024
min-width: inherit !important;
}--*/
.jTPWeN {
  padding: 20px;
}

.desk {
  display: block;
}

.mob {
  display: none;
}

.mYSul {
  border: 1px solid #dededf;
}

.cxFtRn {
  border-top: inherit !important;
}

.jiAnER {
  min-height: 60px !important;
}

.item-list {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

.group-list1 {
  display: flex;
  align-items: center;
}

.group-list1 .image-icon {
  background: #fd1e00;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon1 {
  background: #2db441;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon2 {
  background: #fb8b05;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon3 {
  background: #043bc7;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .image-icon4 {
  background: #f9c114;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-list1 .content {
  margin-left: 20px;
}

.group-list1 .content h2 {
  font-weight: bold;
  color: #403f3f;
  font-size: 35px;
}

.group-list1 .content p {
  color: #797777;
  font-size: 14px;
  font-weight: 400;
}

.Bidder-sec {
  padding: 20px;
}

.Bidder-sec h2 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.jbrMhe {
  opacity: 0;
}

.chart-pie {
  border: 40px solid #043bc7;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
}

.grop-section {
  position: relative;
}

.icon-back {
  position: absolute;
  top: 0;
}

.icon-back a {
  color: #fff;
}

.chart h3 {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.list-circle {
  background: #043bc7;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #767575;
}

.list-circle-pro {
  background: #f32f45;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.list-circle-up {
  background: #f9c114;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.group-list-item {
  position: relative;
}

.number-List {
  position: absolute;
  top: 2px;
  right: 103px;
  background: #fff;
  width: 45px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.number-List2 {
  position: absolute;
  top: 188px;
  right: 77px;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.number-List3 {
  position: absolute;
  top: 137px;
  left: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.Chart-sec {
  min-height: 300px;
  padding: 20px;
  position: relative;
}

.heading_sumary {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}

.icons-sidebar {
  font-size: 24px;
}

.form-check-input {
  width: 25px;
  height: 25px;
}

.form-check {
  align-items: center;
  display: flex;
}

.form-check-label {
  margin-left: 5px;
}

.modal-registration>.modal-header {
  display: none;
}

.border-custom {
  border-left: 1px solid #b9b9b9;
  padding-left: 60px;
}

.cancel-btn {
  background: #ababab;
  color: #fff;
  border: none;
  width: 145px;
  height: 49px;
  border-radius: 5px;
}

.update-btn-prof {
  width: 145px;
  height: 49px;
  margin-left: 10px;
  background: #043bc7;
}

.profile-sec h5 {
  color: #043bc7;
}

.icons-check {
  background: #cfd9f1;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  margin: auto;
}

.icons-check-bell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  margin: auto;
}

.group-modal {
  position: relative;
}

.button_back_login {
  background: #043bc7;
  width: 82%;
  margin: 10px 0px 20px 0px;
}

.button-ok {
  background: #043bc7;
  margin: 10px 0px 20px 0px;
  padding: 12px 50px;
}

.pagenotfound-section {
  background-image: url("/public/image/Page_not _found.png");
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pagenotfound-section h1 {
  color: #043bc7;
  font-size: 10rem;
}

.pagenotfound-section h3 {
  color: #5b5b5b;
  font-size: 32px;
}

.pagenotfound-section p {
  color: #4b4b4b;
  font-size: 20px;
}

.pagenotfound-section .back_button {
  background: #043bc7;
  padding: 15px 85px;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 20px;
}

.permonthtext {
  font-size: 18px !important;
}

.icons-check-bell img {
  width: 98px;
  margin-top: 17px;
}

.icon-dash path {
  stroke: #fff;
}

.sidebar-links a {
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  padding: 10px 15px;
  border-radius: 10px;
}

.sidebar-links.active a {
  color: #043bc7;
  background: #fff;
}

.sidebar-links.active a .icon-dash path {
  stroke: #043bc7;
}

.sidebar-links.active a path {
  fill: #043bc7;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #043bc7;
  color: #fff;
  border: none;
}

.nav-tabs {
  border: none;
  justify-content: end;
}

.nav-link {
  background: #dfe6fb;
  border: none !important;
  color: #000;
}

.list-item ul li {
  line-height: 2;
}

.status-request {
  font-weight: 700;
  font-size: 30px;
}

.btn-close-demo {
  margin: 10px 0px 20px 0px;
  padding: 12px 50px;
}

.group_data_table {
  background: #fff;
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  flex: 1 0 auto;
}

.group_data_table .search-input {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 99;
  width: 300px;
}

.card {
  border: 0px;
}

.mYSul {
  border-radius: 5px;
}

.rdt_Pagination {
  border-radius: 8px;
}

.select-option {
  height: 46px;
}

.btn-Save {
  padding: 10px 40px;
  background: #043bc7;
}

.btn-Cancel {
  padding: 10px 40px;
  background: #ababab;
  border: #ababab;
}

.btn-Cancel:hover {
  background: #898787;
  border: #898787;
}

.auction-heading {
  color: #043bc7;
  font-size: 20px;
  font-weight: 700;
}



.editprofileimage {
  position: relative;
  width: 300px;
  margin: auto;
}

.editprofileimage img {
  width: 250px;
  border-radius: 50%;
  height: 250px;
  border: 2px solid #b9b9b9;
}

.rs-picker-popup .rs-calendar-header-month-toolbar {
  float: none;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0px);
}

.rs-picker-popup .rs-picker-toolbar {
  max-width: 100%;
  position: absolute;
  top: 15px;
  border: none;
}

.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 4px auto;
}

.editprofileicon {
  position: absolute;
  top: 44px;
  right: 13px;
  width: 40px;
  height: 40px;
  background: #043bc7;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editprofileicon input {
  display: none;
}

.text-color-format {
  color: #afb2bb;
}

.item-sec {
  background: #fff;
  padding: 15px 30px 15px;
  border-radius: 5px;
  position: relative;
}

.item-sec .item-1 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #2db441;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-sec .content {
  text-align: center;
}

.item-sec .group-content {
  display: flex;
}

.item-sec .vl {
  border-left: 1px solid #dbdbdb;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 53px;
}

.item-sec h4 {
  color: #403f3f;
  font-size: 20px;
  margin-bottom: 15px;
}

.item-2 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #043bc7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputCheck {
  height: 25px !important;
  width: 25px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.profile-form-sec input {
  height: 46px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rmsc .dropdown-heading {
  height: inherit !important;
  padding: 6px !important;
}
// .tabs-box .rmsc .dropdown-container {
//   width: 250px;
// }


.registration_tournament .form-control {
  height: 46px;
}

.sec-item {
  background: #fff;
  border-radius: 8px;
}

.item-link {
  width: 100%;
  display: block;
  padding: 3px 13px;
  color: #000;
  text-decoration: none;
}

.imageselected {
  width: 150px;
}

.Toastify__close-button>svg {
  margin-top: 16px !important;
}

.icon-bike {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.select-sport {
  height: 46px;
}

.add-date {
  position: relative;
}

.date-picker {
  position: absolute;
  top: 15px;
  right: 15px;
}

.brackets-btn {
  width: 145px;
  height: 49px;
  background: #525252;
  border-color: #4e4c4c;
}

.group-header {
  position: relative;
}

.Login-form-close {
  position: absolute;
  top: 0;
  right: 0;
}

.visible-con {
  visibility: hidden;
}

.banner-content img {
  width: 450px;
}

.heading-auction {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
}

/****************/
.image-chosse {
  width: 100%;
  background: #f5f4f4;
  padding: 10px;
  width: 55%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  height: 200px;
  border: 2px dotted #a9a8a8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-camera {
  font-size: 2rem;
  color: #8f8f8f;
}

.input-check-group {
  display: flex;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.input-check-group {
  font-size: 1.1rem;
}

.image-upload-mess {
  font-size: 1.1rem;
}

.active-auction {
  background: #c1f6c9;
  padding: 10px 26px;
  border: none;
  border-radius: 50px;
  color: #2db441;
}

.btn-aucion {
  background: none;
  border: none;
  font-size: 1.6rem;
  font-weight: bolder;
}

.SportNameAdd {
  border: 1px solid #cdcdcd;
  padding: 5px 8px;
  margin-top: 10px;
  border-radius: 8px;
}

.select-option.tournament {
  height: 45px;
}

.label {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.input-game {
  border: 1px solid #cdcdcd;
  height: 45px;
  padding: 10px;
  border-radius: 10px;
}

.table-team th {
  background: #e5edff;
  font-size: 15px;
}

.inactive-auction {
  background: #ffdfdf;
  color: #ff0000;
  padding: 10px 26px;
  border: none;
  border-radius: 50px;
}

.button-create-auction {
  background: #043bc7;
  border-color: #043bc7;
}

.footer-content {
  margin: 0px 20px 20px 0px;
  margin-left: 270px;
}

.imageAuction {
  width: 150px;
}

.winner-distribution {
  color: #043bc7;
  border-bottom: 2px solid;
  font-weight: 600;
  margin: 25px 0px 25px 0px;
  font-size: 18px;
}

.winner-percentage {
  border: 1px solid #c3c3c3;
  margin-top: 25px;
  padding: 20px;
  border-radius: 10px;
}

.text-color {
  color: #0e751e;
  font-weight: 600;
  margin-bottom: 8px;
}

.icon-plus {
  font-size: 1.8rem;
}

.Login-form .email-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.registration input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.line {
  border: 1px solid #464444;
}

.registration input::-moz-placeholder {
  font-size: 15px;
}

.registration input::placeholder {
  font-size: 15px;
}

.text-color-la {
  color: #cecece;
  position: relative;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #303030;
  border: 1px solid #585858;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
/* Show the checkmark when checked */
input:checked~.checkmark:after {
  display: block;
}

.checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.input-checkbox {
  height: inherit !important;
}

input:checked~.checkmark {
  background-color: #043bc7;
  border: 1px solid #1656fb;
}

button#justify-tab-example-tab-profile {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  font-size: 14px;
}

#justify-tab-example-tab-Amount {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  font-size: 14px;

}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-grow: inherit;
}

.tabs-box .nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  padding: 8px 20px 8px 20px;
  min-width: 150px;
}

.revenue-sec .nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  padding: 8px 20px 8px 20px;
  min-width: 100%;
  border-radius: 4px;
}

.sort-status {
  width: 70%;
}

.search-input.filter {
  width: 260px;
}

.submit-btn {
  background: #043bc7;
  padding: 8px 30px;
}

.text-danger {
  color: #ff0000 !important;
}

.text-success {
  color: #0e751e !important;
}

.submit-btn:hover {
  background: #043bc7;
}

.form-check-input:checked {
  background-color: #043bc7;
  border-color: #043bc7;
}

.eEletC {
  border-radius: 8px;
}

.group-chart {
  position: relative;
}

.text-color-tab {
  color: #000;
}

.PhoneInputInput {
  flex: auto !important;
  min-width: inherit !important;
  width: 45px;
  border: inherit;
  background: inherit;
}

.group-ph {
  position: relative;
}

.phone-number-input {
  padding-left: 114px;
}

.PhoneInputCountryIcon--border {
  background-color: inherit !important;
  box-shadow: inherit !important;
}

.PhoneInput {
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 11px;
  background: inherit;
  border-radius: 1px solid;
  border-right: 1px solid #d3d3d3;
}

.PhoneInputCountry {
  line-height: 0px !important;
}

input.PhoneInputInput:focus-visible {
  outline: 0;
}

.heading-dash {
  color: #043bc7;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.bg-gray-view {
  background-color: #dfe6fb !important;
}

.dotsicon {
  font-size: 1.5rem;
  color: #0c266c;
}

/*----------03/04-24------------------*/
.viewButton {
  background: #bfd1ff;
  color: #043bc7;
}

.tablebtn {
  border: 0;
  padding: 5px 16px;
  border-radius: 50px;
  font-weight: 600;
  align-items: center;
  display: flex;
  min-height: 32px;
}

.viewButton svg {
  font-size: 22px;
  margin-right: 10px;
}

.active_text {
  color: #2db441;
  font-weight: 600;
  margin: 0;
}

.sort-status {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}

.close-btn {
  background: #043bc7;
  border: 0;
  position: absolute;
  top: -21px;
  right: -21px;
  border-radius: 66%;
  height: 43px;
  width: 43px;
  padding: 10px;
}

.close-btn:hover {
  background: #043bc7;
}

/*-----tabs---------------*/
.tabs-auction .nav-tabs {
  border: none;
  justify-content: start;
  border-bottom: 1px solid #e5e4e4;
}

.nav-link.active {
  border-bottom: 1px solid #fed28a !important;
  color: #fed28a !important;
}

.tabs-auction .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent;
  color: #043bc7;
  font-weight: 600;
  border: 1px solid #043bc7 !important;
}

.tabs-auction .nav-link {
  background: transparent;
  border: none !important;
  color: #000;
}

.tabs-box {
  padding: 20px;
}

.auctiontabs .jTPWeN {
  padding: 0px 0;
}

.search-input.filter.auction-filter {
  display: flex;
  align-items: center;
  position: unset;
  float: right;
  justify-content: end;
}

.active-status {
  background: #c1f6c9;
  color: #2db441;
}

.placebid {
  background: #043bc7;
  color: #fff;
  font-weight: 400;
}

.table-team.table-bordered> :not(caption)>*>* {
  border-width: 0;
  font-size: 14px;
  padding: 16px;
}

.modal-footer {
  padding: 0;
  border-top: 0;
}

.notification {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
}

// .notification-right {
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
// }

.notification-right h1 {
  font-size: 16px;
  font-weight: 600;
  color: #2c2828;
  line-height: 1;
  margin-bottom: 10px;
}

.notification-right p {
  font-size: 13px;
  color: #646060;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #caaeed;
  color: #fff;
}

.group_data_table.list-player {
  padding: 20px;
}

.player-anouncement {
  padding: 20px;
}

.group_data_table.admin-auction {
  padding: 20px;
}

.group_data_table.View-organization {
  padding: 20px;
}

.group_data_table.smbid {
  padding: 20px;
}

.smannouncement {
  padding: 20px;
}

.pagination.notification-sec {
  justify-content: end;
  margin-top: 10px;
}

.pagenation-item.active {
  background: #004299;
  color: #fff;
  padding: 8px 15px;
}

.notification-super {
  line-height: 40px !important;
  margin-bottom: 0px !important;
}

.icons-sidebar.announcement {
  font-size: 35px;
}

.notification-area .Notification-Date {
  position: absolute;
  right: 0;
  top: 2px;
  color: #706f6f;
  font-size: 13px;
}

.notification {
  position: relative;

}

.orgnization-sec.Invoice {
  height: 400px;
}

.orgnization-sec.Recent-manager {
  height: 563px;
}

.noti-msg {
  width: 100%;
}

.pagenation-item {
  padding: 8px 12px;
  background: #fff;
  border: 1px solid #d7d7d7;
}

.timeago {
  background: #f3f3f3;
  height: 32px;
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: #545050;
}

.notification_box {
  padding: 20px;
}

.subscription {
  padding: 20px;
  width: 100%;
}

.subscription_heading {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 10px;
}

.subscription_box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}

.subscription_box-inner {
  display: flex;
}

.subscription_box-inner h4 {
  font-size: 26px;
  font-weight: 600;
  min-width: 400px;
}

.subscription_box-inner p:first-child {
  min-width: 400px;
  color: #2f2f2f;
}

.subscription_box-inner-btn {
  display: flex;
}

.subscription_box-inner-btn button:first-child {
  background: #043bc7;
  color: #fff;
  margin-right: 20px;
}

.subscription_box-inner-btn button:last-child {
  background: #0e751e;
  color: #fff;
}

.subscription_box-inner button {
  background: #0e751e;
  color: #fff;
  border-radius: 20px;
  padding: 0 28px;
  border-color: #0e751e;
}

.table-transaction {
  padding: 10px 20px 10px;
  position: relative;
}

span.status-com {
  background: #0e751e;
  color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
}

.subscription.chart {
  padding: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.apexcharts-legend-text {
  padding-left: 15px;
  margin-left: -5px !important;
  padding: 0 30px;
}

.dropdown-icon.dropdown .btn {
  background-color: #fff;
  border: 0;
}

.dropdown-icon.dropdown .dropdown-toggle::after {
  display: none;
}

.block.dropdown-item:focus,
.dropdown-item {
  padding: 10px 16px;
}

.block.dropdown-item {
  color: #fff;
  background-color: #e5808a !important;
  padding: 10px 16px;
  border-radius: 3px;
}

.unblock.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #9ea19f !important;
}

.dropdown-menu.show {
  padding: 0;
  border-color: #f3f3f3;
}

.profile-pic2 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #dc3545;
  color: #fff;
}

.profile-pic3 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #0e751e;
  color: #fff;
}

.profile-pic4 {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  background: #561d0c;
  color: #fff;
}

button.view-eye.btn-aucion.ms-1 {
  color: #043bc7;
}

.strip.row {
  background: #043bc7;
  color: #fff;
  padding: 8px 2px;
  margin: 20px 0px;
  border-radius: 5px;
  align-items: center;
}

.stor-btn {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 0 10px;
  border-radius: 4px;
}

.cancel-btn {

  color: #fff;
  padding: 0px 2px;
  border-radius: 5px;
  border: 0;
}

.icon-bullet {
  fill: #fff;
}

.role-btn {
  background: #0e751e;
}

/*----------home--------------*/
.banner {
  min-height: 75vh;
  width: 100%;
  background: url("/public/image/banner-light.png");
  background-repeat: no-repeat;
  background-color: #041f81;
  background-position: bottom;
  background-size: cover;
  position: relative;
  margin-top: 108px;
}

.image_one {
  position: absolute;
  right: 0;
  width: 58%;
}

.header .nav-link {
  background: transparent;
  color: #fff;
  font-size: 18px;
  padding: 5px 11px;
  margin: 0 11px;
}

.header .nav-link:active {
  border-bottom: 2px solid #ffc107 !important;
}

.header .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fed28a;
  border-bottom: 1px solid #fed28a !important;
}

nav.header.navbar.navbar-expand-lg.navbar-light {
  background: #051f87;
  padding: 0px 63px;
}


.btn-outline-success {
  color: #ffffff;
  font-size: 20px;
  margin-right: 15px !important;
  font-weight: bold;
  padding: 12px 40px !important;
  border: 1px solid;
}

.btn-outline-success1 {
  color: #040404;
  font-size: 20px;
  margin-right: 15px;
  font-weight: bold;
  background: #ffd48c;
  padding: 12px 40px;
}

.btn-outline-success1:hover {
  border-color: #ffd48c;
  background: transparent;
}


.header .btn.btn-primary {
  background: #455aad;
  color: #ffffff;
  font-size: 20px;
  border: 0;
  font-weight: 600;
  padding: 12px 40px;
}

.text-area h1 {
  font-size: 53px;
  font-weight: 600;
  margin-top: 50px;
  text-transform: uppercase;
}

p.paragraph {
  font-size: 18px;
  font-size: 18px;
  width: 655px;
  padding: 10px 0;
}

.section-text-area {
  width: 100% !important;
}

button.btnone.btn.btn-outline-success {
  padding: 12px 40px;
}

button.btntwo.btn.btn-primary {
  padding: 12px 40px;
  font-size: 20px;
  margin-right: 15px;
  font-weight: bold;
  background: #fff;
  color: #043bc7;
}

.second.card {
  position: relative;
  margin: 100px auto;
  height: 314px;
  padding: 0px;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #0168ff;
  border-radius: 33px;
}

.second.card .content {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.second.card h1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  color: #0b143f;
}



.img.image-card {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
}

.second.card h2 {
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 10px;
}

.postion {
  position: relative;
}

.second.card.cardone {
  transform: rotateZ(344deg);
  margin-top: 170px;
  position: absolute;
  width: 400px;
  left: -5%;
  z-index: 9;

}

.xcutta-reg-free {
  border: none !important;
}

.section-auction h1.auction-img {
  font-size: 112px;
  color: #bfbfbf;
  letter-spacing: 4px;
}

.title {
  position: absolute;
  margin: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  bottom: 128px;
}

.title2 {
  margin: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  bottom: 128px;
}

.headingcenter {
  position: absolute;
  text-align: center;
  align-self: center;
  display: flex;
  margin: auto;
  line-height: 1.3;
  margin-top: 35px;
}

.bidAllData .rdt_TableHeader {
  display: none;
}

.second.card.thirdcard {
  transform: rotateZ(14deg);
  margin-top: 170px;
  position: absolute;
  width: 400px;
  right: -5%;
  z-index: 9;

}

.padding {
  padding: 240px 0px;
}

.thirdimg {
  position: absolute;
  bottom: 0;
  padding: 18px 42px;
}

.paragraphtext {
  font-size: 21px;
  color: #4e4e4e;
  font-weight: 600;
  padding: 0 307px;
}

.section-auction {
  background: #f1f1f1;
}

.section-auction h1 {
  font-size: 40px;
  font-weight: 600;
  color: #0b143f;
}

section.section-auction.padding2 {
  padding: 40px 0;
  text-align: center;
}

.second.card.padding-section {
  box-shadow: none;
  padding: 64px;
  height: 400px;
}

.section-auction.padding2.bg-img {
  background: url("/src/assets/images/auction-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;
}

.second.card.padding-section.subscrption-card {
  border: 3px solid #043bc7;
  border-radius: 10px;
  padding: 40px;
}

.title2 .title-text {
  font-weight: 700 !important;
  font-size: 40px !important;
}

.tab-list-item.player {
  padding-bottom: 44px;
}

.dropdown-manager-approve {
  position: absolute;
  top: 10px;
  right: 10px;
}

.auction-select-manager {
  position: absolute;
  top: 0;
}

.input-radio-button {
  width: 30px;
  height: 30px;
}

.group-input-feild {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-value {
  font-size: 2rem;
  margin-left: 11px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  line-height: 35px;
  display: inline-block;
  color: #666;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.price {
  font-size: 35px;
  font-weight: 600;
  color: #043bc7;
}

.month {
  font-size: 25px;
  font-weight: bold;
}

.slash {
  color: #000;
  font-size: 35px;
  font-weight: bold;
  margin: 0 5px;
}

.outline-success.subscription {
  color: #043bc7;
  font-size: 20px;
  margin-right: 15px;
  font-weight: bold;
  padding: 12px 40px;
  border: 1px solid #043bc7;
  background: #f3f6ff;
}

.btn-outline-success-three {
  color: #043bc7;
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
  font-weight: bold;
  padding: 12px 40px;
  border: 1px solid;
  background: #f3f6ff;
}

p.or {
  font-weight: bold;
  color: #000;
}

section.section-auction.padding2.registration-sec {
  background: #fff;
}

.title2.text-left {
  text-align: left;
}

h1.pt-4.pb-4.text-regiter {
  color: #0b143f;
  font-size: 29px;
  font-weight: 700;
  line-height: 50px;
}

footer.landing {
  background: #000;
  color: #fff;
  padding: 27px 51px;
}

footer.landing p {
  margin: 0;
}

.checkout-form {
  width: 588px;
  padding: 23px;
}

.payment-check {
  font-size: 60px;
  color: #6ead4c;
}

.payment-check-cancel {
  font-size: 60px;
  color: #f44336;
}

.payment-success {
  height: 100vh;
  display: flex;
  align-items: center;
}

/*# sourceMappingURL=main.css.map */
a.view-List {
  text-decoration: underline;
  color: #004299;
}


footer.landing p {
  margin: 0;
}

.checkout-form {
  width: 588px;
  padding: 23px;
}

.payment-check {
  font-size: 60px;
  color: #6ead4c;
}

.payment-check-cancel {
  font-size: 60px;
  color: #F44336;
}

.payment-success {
  height: 100vh;
  display: flex;
  align-items: center;
}

.bracket.disable-image.bg-white {
  min-height: 50vh;
}

.setup-bracket-butt {
  padding: 0px 2px;
  border-radius: 5px;
  width: 145px;
  height: 49px;
}

.viewButton svg {
  font-size: 22px;
  margin-right: 0px;
}

/***************/
.user-list-item {
  color: #fff;
}

.dropdown:focus-visible {
  outline: 0;
}

.user-list-item .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #fff;
  background-color: var(--bs-btn-active-bg);
  border: inherit !important;
}

.dropdown-section .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #000;
}

.dropdown-section .btn:hover {
  color: #000;
}

.user-list-item .btn:hover {
  color: #fff !important;
}

.header .nav-link:hover {
  border-bottom: 1px solid #fed28a !important;
  color: #fed28a;
  text-decoration: none;
}

.Link-to-page {
  text-decoration: none;
  color: inherit;
}
.Link-to-page:hover {
  text-decoration: none;
  color: inherit;
}

.tablebtn.placebid.disable {
  background: #cbcbcb !important;
}

.banner-item {
  background: #051f87;
  height: calc(100vh - 147px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  h1 {
    font-size: 6rem;
    color: #fed28a;
  }
}



/***************/
.list-group-sec {
  user-select: none;
  display: flex;
  justify-content: flex-start;
}

.item-dragable {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(84, 84, 241);
  color: white;
  margin: 8px;
  width: 60px;
  height: 60px;
  cursor: grab;
}

.dragged {
  background-color: rgb(37, 37, 197);
}

.group_data_table.tabs-auction .tab-list-item .nav-item {
  flex-basis: inherit !important;
}

.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
  width: 30px !important;
  height: 30px !important;
}

.rs-loader-spin::after {
  border-color: #043bc7 transparent !important;
}

.rs-loader-wrapper {
  display: flex !important;
  width: auto;
  /* line-height: 41; */
  margin: 8px auto;
  align-items: center;
  justify-content: center;
}

.fENAMc {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.keMQur {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.notification-icon {
  background: none;
  color: #043bc7;
  font-size: 23px;
  margin-right: 10px;
  position: relative;
}

.notification-icon .dropdown-menu.show {
  padding: 10px;
}

.notification-icon:hover {
  background: none;
}

.notification-icon::after {
  display: none;
}

.notification-icon .user-list-item .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #043bc7;
  background-color: inherit !important;
  border: inherit !important;
}

.notification-message .dropdown-menu.show {
  padding: 10px;
  margin-top: 6px;
}

.badge-notification {
  position: absolute;
  top: -2px;
  right: -3px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #fff;
  background: #dc3545;
  justify-content: center;
  border-radius: 50% !important;
}

.kzZBXj {
  overflow: inherit !important;
  white-space: inherit !important;
  text-overflow: inherit !important;
}

.QAQQD {
  flex: 1 0 auto;
  font-weight: 400;
  display: none;
}

.auction-manager {
  width: 520px !important;
}

.notification-dash {
  overflow: auto;
  height: 95vh;
}

.announcementtab {
  height: 46vh;
  overflow: auto;
}

select.select-option.tournament.form-select option:first-child {
  display: none;
}

.input-score {
  width: 50px;
  height: 35px;
  margin: 0px 10px;
}

.eFEHrh div:first-child {
  white-space: inherit !important;
  overflow: inherit !important;
  text-overflow: inherit !important;
}

.rdt_TableHeadRow {
  background-color: #f5f5f5 !important;
}

.Nfhbm {
  padding: 15px !important;
}

.saveScoreBtn {
  background: #0e751e;
  color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 34%;
  font-size: 14px;
  padding: 4px;
}

.rs-picker-popup .rs-calendar-header-month-toolbar {
  transform: translate(-50%, -13px) !important;
}

.date-match {
  position: absolute;
  top: 131px;
}

.suffle-icon {
  font-size: 20px;
}

.save-icon {
  font-size: 20px;
}

.banner-item-About-us {
  background-image: url(/public/image/about-us.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 75%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  h2 {
    color: #fff;
    text-align: center;
  }

  h4 {
    color: #fff;
    text-align: center;
    font-weight: 200;
  }

  h3 {
    color: #fff;
  }

  p {
    color: #fff;
    text-align: center;
  }
}
.footer-banner-item.managerTips{
  background-image: url(/public/image/ManagerTipsFooterBanner.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 20%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.banner-item-Faq2.footer{
  background-image: url(/public/image/FAQBottom.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 64%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.banner-item-Manager-tips {
  background-image: url(/public/image/ManagerTips.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 20%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  h2 {
    color: #fff;
    text-align: center;
  }

  h4 {
    color: #fff;
    text-align: center;
    font-weight: 200;
  }

  h3 {
    color: #fff;
  }

  p {
    color: #fff;
    text-align: center;
  }
}

.banner-item-how-it-work {
  background-image: url(/public/image/how-it-work.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 2%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

}

.banner-item-contact-us {
  background-image: url(/public/image/contact-us.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% 27%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

}

.banner-item-Faq {
  background-image: url(/public/image/Faq.jpg);
  width: 100%;
  background-size: cover;
  height: 600px;
  background-position: 100% -10%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

}

.team {
  padding: 100px 0;
  padding-bottom: 70px;
  background: #F6F6F6;
}

.title1 {
  padding-bottom: 50px;
  overflow: hidden;
}

.title1 h2 {
  font-weight: 600;
  font-size: 35px;
  color: #232323;
}

.team .team-member .member-content {
  padding: 25px 0;
}

.team .member-photo {
  overflow: hidden;
  position: relative;
}

.team .team-member {
  background: #fff;
  margin-bottom: 30px;
}

.team .member-photo img {
  transition: all 0.6s ease 0s;
}

.team .member-photo:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team .member-photo .mask {
  background: rgba(40, 171, 227, 0.7);
  position: absolute;
  width: 100%;
  bottom: 0;
  top: auto;
  opacity: 0;
  filter: alpha(opacity=0);
  left: 0;
  right: 0;
  cursor: pointer;
  padding: 0;
  height: 80px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all .3s ease;
}

.team .member-photo .mask ul {
  text-align: center;
  position: relative;
  margin-top: 22px;
}

.team .member-photo:hover .mask {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.team .member-photo .mask ul li {
  display: inline-block;
  margin-right: 7px;
}

.team .member-photo .mask ul li a {
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  display: block;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.Get-contact {
  background: #051f87;
  color: #fff;
  padding: 30px;
  position: relative;

  h4 {
    line-height: 1.2;
  }
}

.u-social-icons.u-spacing-17 {
  position: absolute;
  bottom: 30px;
}

.icon-contact {
  color: #fff;
  font-size: 18px;
}

.text-content-contact {
  color: #eaebed;
  font-size: 1rem;
}

.text-area {
  color: #f1c50e;
  font-size: 20px;
  margin-left: 35px;
}

.contact-area {
  background: #dce0f1;
  padding: 75px;
}

.u-icon.u-social-facebook svg {
  fill: #fff;
}

span.u-icon.u-social-facebook.u-social-icon.u-text-white {
  margin-left: 17px;
  font-size: 24px;
}

.cont-form {
  padding: 30px;
}

.carousel-control-prev {
  left: -87px !important;
  background: #032c95;
  width: 52px;
  top: 50% !important;
  border-radius: 50%;
  opacity: inherit !important;
  height: 52px;
}

.carousel-control-next {
  right: -87px !important;
  background: #032c95;
  width: 52px;
  top: 50% !important;
  border-radius: 50%;
  opacity: inherit !important;
  height: 52px;
}

.item-slider {
  box-shadow: 0px 6px 11px rgb(233 239 255);
  background: #ffffff;
  width: 200px;
  height: 200px;
  border: 1px dashed #051f87;
  border-radius: 12%;
  background: #f7faff;
  display: flex;
  margin: 14px;
  justify-content: center;
  align-items: center;
}

.section-auction-sport h1 {
  font-size: 40px;
  font-weight: 600;
  color: #0b143f;
}

.react-multiple-carousel__arrow--left {
  left: 12px !important;
}

.react-multiple-carousel__arrow {
  background: #051f875c !important;

}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.notification-admin {
  height: 400px;
  overflow: auto;
}

.message-query {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  padding: .5em;
  width: 150px;
  max-width: 100%;
  transition: max-width linear .5s;
}

.message-query:before {
  background-color: #ffd694;
  border: 1px solid #e1cdae;
  content: "";
  height: 47px;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 3px;
  position: absolute;
  z-index: -1;
}

.table-transaction .gjoprN {
  position: unset !important;
}

.table-transaction .sc-dhKdPU.kqIJNV.rdt_TableRow {
  position: relative;
}

.message-query:hover {
  overflow: visible;
  white-space: normal;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  max-width: 400%;
  width: 400%;
  z-index: 1;
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border: 0;
}

.subscription_heading.ms-4.quries {
  opacity: 0;
}

.dropdown-search-clear-icon.gray {
  display: none;
}

.search-player-auction {
  opacity: 0;
  display: none;
}

.auction-filter.palyer {
  width: 360px;
}

.section-auction-sport .item-slider img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.rs-calendar-body {
  padding: 42px 12px 12px !important;
}

.heading-how-itswork {
  color: #004299;
  font-size: 24px;
}

.bid-search.filter {
  width: 550px;
}

.input-checkbox:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.rs-calendar-time-dropdown {
  top: 60px !important;

}

.gxfiQ {
  display: none !important;
}

.team-auction-update {
  height: 163px;
  overflow: auto;
  margin-top: 11px;
}

.Faq-sec .accordion-header .accordion-button {
  padding: 0px 15px !important;
  font-weight: 500;
  background: #f2efff;
}

.Faq-sec .accordion-header .accordion-button:not(.collapsed) {
  color: #000000;
  font-weight: 600;
  background-color: rgb(157 157 255 / 60%);
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.Faq-sec .accordion-item {
  color: var(--bs-accordion-color);
  border: 1px solid #c3c3ff;
  margin: 8px;
}

.slider-image {
  max-height: 350px;
}

.jDxjva {
  padding: 10px !important;
}

.ropdown-manager-approve {
  position: relative;
  float: right;
}

.ropdown-manager-approve .rmsc .dropdown-container {
  position: relative;
  outline: 0;
  width: 300px;
  /* float: right; */
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}

.slider-image-about {
  width: 100%;
  height: 536px;
  object-fit: contain;
  object-position: center;
}

.heading-sports {
  border-bottom: 2px solid #000000;
  padding: 8px;
  color: #000000;
}

.box {
  display: flex;
  flex-direction: row;
}

.notification-text-mess {
  width: 16rem;
}

.text-message-area {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right-date {
  text-align: right;
  font-size: 13px;
}

.Link-to-page:hover {
  text-decoration: none !important;
}

li.list-tips-item {
  text-align: left;
  border: 1px solid rgba(157, 157, 255, 0.6) !important;
  margin-bottom: 15px;
  border: none;
  border-radius: inherit;
  display: flex;
  align-items: center;
  padding: 0px;

}

.list-group-numbered>.list-group-item::before {
  background: rgb(228 235 255 / 60%);
  padding: 10px;
  line-height: 0.5;
  margin-right: 12px;
  font-size: 18px;
  color: #313145;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px 18px;
  width: 50px;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, "") "";
  counter-increment: section;
}

.second.card.thirdcard h1 {
  line-height: 1.2;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.password-input-Change {
  padding-right: 70px;
}

#auctiontabs-tabpane-Sell Auction {
  display: none;
}

.landing-header-logo {
  width: 250px;
}

/*********************/
.rhm-rn-navigation ul:not(.rhm-rn-navigation__logo) li {
  padding: 0px !important;
}

.rhm-rn-navigation__button {
  height: inherit !important;
}
.mob-footer{
  display: none;
}

.rhm-rn-navigation__nav {
  max-height: inherit !important;
}
.table-sm>:not(caption)>*>* {
  padding: 15px 16px;
  width: 300px;
}

.rhm-rn-navigation ul:not(.rhm-rn-navigation__logo) {
  align-items: center;
  display: inline !important;
}
.rhm-rn-navigation__nav{
  padding: 0 50px !important;
}

.rhm-rn-navigation__nav {
  height: inherit !important;
  position: fixed;
  opacity: 1;
  width: 100% !important;
  transition: all var(--rn-animation-delay) ease-out;
  top: 0;
  background-color: #052090 !important;
  left: 0;
  overflow: hidden;
  z-index: calc(var(--z-index) - 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.promo-code-header {
  background: #021048;
  color: #fff;
}

.promo-close .btn-close {
  display: none;
}

.bg-promocode-btn {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
  font-weight: bold;
  padding: 12px 40px;
  border: 1px solid;
  background: #0d6efd;
}

.red-timer {
  color: red;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.list-group.list-group-flush .list-group-item {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.dateMatach{
  width: 100%;
}
.text-score-update{
  padding: 10px;
  background: #e5ebff;
  color: #043bc7;
  border-radius: 5px;
  margin-right: 1rem;
}
.form-contnet .logo-mob-img{
  width: 200px;
  display: none;
  } 

  .auction-closed-status .rdt_TableCell:last-child{
    background: #f4f0f0;
    position: sticky;
    right: 0;
  }
  .auction-closed-status .rdt_TableCol:last-child{
    background: #f4f0f0;
     position: sticky;
    right: 0;
  }
.bidWinner{
  font-size: 24px;
  font-weight: 600;
  color: #0e751e;
}
.saveScoreBtn.disable{
  background: #a9a9a9;
  }
 